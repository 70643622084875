import React, {useCallback, useEffect, useState, Fragment, useRef} from 'react';
import {useStore} from "../../store/useStore";
import Timer from "./Timer";
import {Box} from "@mui/material";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import axios from "axios";
import config from "../../config/config";
import Notifications from "../notifications/Notifications";
import {LoadingButton} from "@mui/lab";
import {useIntl} from "react-intl";
import GetElement from "../../helper/GetElement";
import PropTypes from "prop-types";
import {
    getLastModuleAnsweredAt,
    getLastPageAnsweredAt,
    getRestTimeByTimestamps,
    isOcsModulePage
} from "../../helper/Helper";
import * as Sentry from "@sentry/react";
import axiosRetry from "axios-retry";

const Content = (props) => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [moduleJson] = useState(state.pinRessource.modulesJson);
    const [moduleIndex, setModuleIndex] = useState((state.moduleIndex >= 0) ? state.moduleIndex : state.pinRessource.currentModule);
    const [module, setModule] = useState(moduleJson[moduleIndex]);
    const [pageIndex, setPageIndex] = useState((state.pageIndex >= 0) ? state.pageIndex : state.pinRessource.currentPage);
    const [page, setPage] = useState(module.pages[pageIndex]);
    const [answerJson, setAnswerJson] = useState((Object.keys(state.answerJson).length) ? state.answerJson : (state.pinRessource.answerJson ?? []));
    const [values, setValues] = useState({});
    const [totalElementAnswers, setTotalElementAnswers] = useState(0);
    const [answeredCounter, setAnsweredCounter] = useState(0);
    const [readyForNextPage, setReadyForNextPage] = useState(true);
    const [loading, setLoading] = useState(false);
    const firstRender = useRef(true);
    const [triggerFinishPageStatus, setTriggerFinishPageStatus] = useState(false);
    const [waitingForFinishingElement, setWaitingForFinishingElement] = useState(false);
    const [triggerFinishPageValue, setTriggerFinishPageValue] = useState(true);

    // Timer
    const [isModuleTime, setIsModuleTime] = useState(false);
    const [isPageTime, setIsPageTime] = useState(false);
    const [timerModuleIndex, setTimerModuleIndex] = useState(null);
    const [timerModuleId, setTimerModuleId] = useState(null);
    const [timerPageIndex, setTimerPageIndex] = useState(null);
    const [timerPageId, setTimerPageId] = useState(null);
    const [calculatedModuleTimerTime, setCalculatedModuleTimerTime] = useState(false);
    const [calculatedPageTimerTime, setCalculatedPageTimerTime] = useState(false);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(GlobalTrans('error_renderer'));

    const increaseTotalElementAnswer = useCallback((amount = 1) => {
        setTotalElementAnswers((prev) => prev + amount);
    }, []);

    const getTotalElementAnswers = useCallback(() => {
        setTotalElementAnswers(0);

        page.elements.forEach((element) => {
            if (element.validationType && !element.noAnswerTrigger) {
                increaseTotalElementAnswer();
            }
        });
    }, [page, increaseTotalElementAnswer]);

    const increaseAnswered = useCallback((amount = 1) => {
        setAnsweredCounter((prev) => prev + amount);
    }, []);

    const decreaseAnswered = useCallback((amount = 1) => {
        setAnsweredCounter((prev) => prev - amount);
    }, []);

    const resetAnswered = useCallback(() => {
        setAnsweredCounter(0);
    }, []);

    const triggerFinishPageFunction = useCallback(() => {
        setTriggerFinishPageStatus(true);
    }, []);

    useEffect(() => {
        dispatch({
            type: 'setModuleIndex',
            payload: moduleIndex
        });
    }, [moduleIndex, dispatch]);

    useEffect(() => {
        dispatch({
            type: 'setPageIndex',
            payload: pageIndex
        });
    }, [pageIndex, dispatch]);

    useEffect(() => {
        dispatch({
            type: 'setAnswerJson',
            payload: answerJson
        });
    }, [answerJson, dispatch]);

    const isOldModuleTime = useCallback(() => {
        return isModuleTime && (timerModuleIndex === moduleIndex || timerModuleId === module.id);
    }, [isModuleTime, timerModuleIndex, moduleIndex, timerModuleId, module.id]);

    const isOldPageTime = useCallback(() => {
        return isPageTime && (timerPageIndex === pageIndex || timerPageId === page.id);
    }, [isPageTime, timerPageIndex, pageIndex, timerPageId, page.id]);

    // When changing module or page, then update the timer
    useEffect(() => {
        if (isOldModuleTime() && isOldPageTime()) {
            return;
        }

        if (!isOldModuleTime() && module.responseTime && !isOldPageTime() && page.responseTime) {
            dispatch({
                type: 'setModuleTimerTime',
                payload: module.responseTime
            });
            dispatch({
                type: 'setPageTimerTime',
                payload: page.responseTime
            });

            setIsModuleTime(true);
            setTimerModuleIndex(moduleIndex);
            setTimerModuleId(module.id);

            setIsPageTime(true);
            setTimerPageIndex(pageIndex);
            setTimerPageId(page.id);

        } else if (!isOldModuleTime() && module.responseTime) {
            dispatch({
                type: 'setModuleTimerTime',
                payload: module.responseTime
            });

            setIsModuleTime(true);
            setTimerModuleIndex(moduleIndex);
            setTimerModuleId(module.id);

        } else if (!isOldPageTime() && page.responseTime) {
            dispatch({
                type: 'setPageTimerTime',
                payload: page.responseTime
            });

            setIsPageTime(true);
            setTimerPageIndex(pageIndex);
            setTimerPageId(page.id);
        } else {
            if (!isOldModuleTime()) {
                dispatch({
                    type: 'setModuleTimerTime',
                    payload: 0
                });
                setIsModuleTime(false);
                setTimerModuleIndex(null);
                setTimerModuleId(null);
            }

            if (!isOldPageTime()) {
                dispatch({
                    type: 'setPageTimerTime',
                    payload: 0
                });
                setIsPageTime(false);
                setTimerPageIndex(null);
                setTimerPageId(null);
            }
        }
    }, [
        module,
        page,
        isModuleTime,
        isPageTime,
        moduleIndex,
        pageIndex,
        timerModuleIndex,
        timerModuleId,
        timerPageId,
        timerPageIndex,
        dispatch,
        isOldModuleTime,
        isOldPageTime
    ]);

    // When changing the module or page index, then update the module and page state
    useEffect(() => {
        if (!firstRender.current) {
            setModule(moduleJson[moduleIndex]);
            setPage(moduleJson[moduleIndex].pages[pageIndex]);
            resetAnswered();
            getTotalElementAnswers();
        } else {
            firstRender.current = false;
            getTotalElementAnswers();
        }
    }, [moduleJson, moduleIndex, pageIndex, getTotalElementAnswers, resetAnswered]);

    const getNextPage = useCallback(() => {
        let nextPageIndex = pageIndex + 1;

        if (nextPageIndex < module.pages.length) {
            return nextPageIndex;
        }

        return false;
    }, [pageIndex, module.pages.length]);

    const getNextModule = useCallback(() => {
        let nextModuleIndex = moduleIndex + 1;

        if (nextModuleIndex < moduleJson.length) {
            return nextModuleIndex;
        }

        return false;
    }, [moduleIndex, moduleJson]);

    const scrollToTop = () => {
        const section = document.querySelector('.main-content');
        section.scrollTo({behavior: 'smooth', top: 0});
    }

    const setNextModule = useCallback(() => {
        const nextModuleIndex = getNextModule();

        if (nextModuleIndex) {
            setValues({});
            dispatch({
                type: 'setModuleTimerTime',
                payload: 0
            });
            dispatch({
                type: 'setPageTimerTime',
                payload: 0
            });
            setPageIndex(0);
            setLoading(false);
            setModuleIndex(nextModuleIndex);
            scrollToTop();
        } else {
            dispatch({type: "deletePin"});
            dispatch({type: "finishPin"});
        }
    }, [getNextModule, dispatch]);

    const setNextPage = useCallback(() => {
        const nextPageIndex = getNextPage();

        if (nextPageIndex) {
            dispatch({
                type: 'setPageTimerTime',
                payload: 0
            });

            setPageIndex(nextPageIndex);
            setValues({});
            setLoading(false);
            scrollToTop();
        } else {
            setNextModule();
        }
    }, [getNextPage, setNextModule, dispatch]);

    const showError = useCallback((errorMessage = '') => {
        if (errorMessage) {
            setErrorMessage(
                <Fragment>
                    <div>{errorMessage}</div>
                    <div>{GlobalTransIntl('error_renderer', intl)}</div>
                </Fragment>
            );
        } else {
            setErrorMessage(GlobalTransIntl('error_renderer', intl));
        }

        setNotificationError(true);
        setLoading(false);
    }, [intl]);

    const submitAnswer = () => {
        if (answeredCounter === totalElementAnswers) {
            sendAnswer();
        }
    }

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    const sendAnswer = useCallback((finishPage = false, type = '') => {
        const finishModule = finishPage && isModuleTime && type === 'module';
        const timestamp = Date.now();
        const hash = {
            uuid: generateUUID(),
            timestamp
        };
        setNotificationError(false);
        setLoading(true);

        let data = {
                addAnswer: {
                    pageId: page.id,
                    elements: values,
                },
                hash
            },
            nextPageIndex = getNextPage(),
            nextModuleIndex = getNextModule();

        if (readyForNextPage || finishPage) {
            if (nextPageIndex && !finishModule) {
                data.addAnswer.nextPage = nextPageIndex;
            } else if (nextModuleIndex) {
                data.addAnswer.nextModule = nextModuleIndex;
            } else {
                data.addAnswer.finished = true;
            }
        }

        axiosRetry(axios, {
            retries: 1,
            retryCondition: (error) => error.code === 'ECONNABORTED',
            shouldResetTimeout: true,
        });

        axios.patch(config.apiUrl + `/pins/` + state.pinRessource.id, data, config.axiosConfig(state.token, {
            headers: {
                'content-type': 'application/merge-patch+json'
            }
        }))
            .then((res) => {
                if (res.data && res.data.answerJson) {
                    setAnswerJson(res.data.answerJson);

                    if (readyForNextPage || finishPage) {
                        if (finishModule) {
                            setNextModule();
                        } else {
                            setNextPage();
                        }
                    } else {
                        setValues({});
                        getTotalElementAnswers();
                        resetAnswered();
                        setLoading(false);
                        scrollToTop();
                    }
                } else {
                    Sentry.captureMessage('Invalid API response');
                    showError();
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                Sentry.captureMessage(JSON.stringify(hash));

                if (error.response && error.response.data['@type'] === 'hydra:Error') {
                    showError(error.response.data['hydra:description']);
                } else {
                    showError()
                }
            });
    }, [page, values, state.pinRessource.id, state.token, setNextPage, getNextPage, showError, getNextModule, readyForNextPage, getTotalElementAnswers, resetAnswered, isModuleTime, setNextModule]);

    const RenderElements = useCallback(() => (
        page.elements.map((element) => {
            if (!props.onlyTasks || element.validationType) {
                return (
                    <Box sx={{mb: (page.elements.length > 1) ? 6 : 0}} key={element.id}>
                        <GetElement
                            element={element}
                            setValues={setValues}
                            increaseAnswered={increaseAnswered}
                            decreaseAnswered={decreaseAnswered}
                            answerJson={answerJson}
                            setReadyForNextPage={setReadyForNextPage}
                            pageId={page.id}
                            triggerFinishedPage={triggerFinishPageFunction}
                            finishingElement={setWaitingForFinishingElement}
                            setTriggerFinishPageValue={setTriggerFinishPageValue}
                        />
                    </Box>
                )
            } else {
                return null;
            }
        })
    ), [page, increaseAnswered, decreaseAnswered, answerJson, triggerFinishPageFunction, props.onlyTasks]);

    const triggerFinishPage = useCallback((type = '') => {
        if (waitingForFinishingElement) {
            dispatch({
                type: 'setFinishingPage',
                payload: true,
            });
        } else {
            sendAnswer(triggerFinishPageValue, type);
            setTriggerFinishPageValue(true);
        }
    }, [waitingForFinishingElement, sendAnswer, dispatch, triggerFinishPageValue]);

    useEffect(() => {
        dispatch({
            type: 'setTriggerFinishPage',
            payload: triggerFinishPage
        });
    }, [dispatch, triggerFinishPage]);

    useEffect(() => {
        if (triggerFinishPageStatus) {
            setTriggerFinishPageStatus(false);
            triggerFinishPage();
        }
    }, [triggerFinishPageStatus, triggerFinishPage]);

    const calculateRestTimeTimestamps = useCallback((startedPageTimestamp, startedModuleTimestamp, calculatedModuleTime, calculatedPageTime, isModuleTime, isPageTime) => {
        if (isModuleTime && isPageTime) {
            const moduleRestTime = getRestTimeByTimestamps(startedModuleTimestamp, calculatedModuleTime);
            const pageRestTime = getRestTimeByTimestamps(startedPageTimestamp, calculatedPageTime);

            if (moduleRestTime && pageRestTime) {
                return {
                    "restModuleTime": moduleRestTime,
                    "restPageTime": pageRestTime
                }
            }
        } else if (isModuleTime) {
            const moduleRestTime = getRestTimeByTimestamps(startedModuleTimestamp, calculatedModuleTime);

            if (moduleRestTime) {
                return {"restModuleTime": moduleRestTime}
            }
        } else if (isPageTime) {
            const pageRestTime = getRestTimeByTimestamps(startedPageTimestamp, calculatedPageTime);

            if (pageRestTime) {
                return {"restPageTime": pageRestTime}
            }
        }

        return false;
    }, []);

    // helper for calculating time, if reload
    const calculateRestTime = useCallback((calculatedModuleTime, calculatedPageTime, isModuleTime, isPageTime) => { // Funktion soll die Restzeiten ausgeben
        if (isModuleTime || isPageTime) {
            let startedPageTimestamp, startedModuleTimestamp;
            const defaultPinTimestamp = Math.floor(new Date(state.pinRessource.startedAt).getTime() / 1000);

            if (isModuleTime && isPageTime) {
                startedPageTimestamp = getLastPageAnsweredAt(moduleJson, pageIndex, moduleIndex, answerJson, state.pinRessource.startedAt);

                if (moduleIndex !== 0) {
                    startedModuleTimestamp = getLastModuleAnsweredAt(moduleJson, moduleIndex, answerJson);
                } else {
                    startedModuleTimestamp = defaultPinTimestamp;
                }
            } else if (isModuleTime) {
                if (moduleIndex !== 0) {
                    startedModuleTimestamp = getLastModuleAnsweredAt(moduleJson, moduleIndex, answerJson);
                } else {
                    startedModuleTimestamp = defaultPinTimestamp;
                }
            } else if (isPageTime) {
                startedPageTimestamp = getLastPageAnsweredAt(moduleJson, pageIndex, moduleIndex, answerJson, state.pinRessource.startedAt);
            }

            const result = calculateRestTimeTimestamps(
                startedPageTimestamp,
                startedModuleTimestamp,
                calculatedModuleTime,
                calculatedPageTime,
                isModuleTime,
                isPageTime
            );

            if (result) {
                return result;
            }

            triggerFinishPage();
        }

        return {
            "restModuleTime": 0,
            "restPageTime": 0
        };
    }, [
        moduleIndex,
        state.pinRessource.startedAt,
        triggerFinishPage,
        calculateRestTimeTimestamps,
        answerJson,
        moduleJson,
        pageIndex
    ]);


    // calculate rest time when first time loading the page while assessment is already started
    useEffect(() => {
        if (!calculatedModuleTimerTime || !calculatedPageTimerTime) {
            setCalculatedModuleTimerTime(true);
            setCalculatedPageTimerTime(true);

            const isModuleTime = !!(module.responseTime);
            const isPageTime = !!(page.responseTime);
            let calculatedModuleTime = module.responseTime;
            let calculatedPageTime = page.responseTime;
            let times = calculateRestTime(calculatedModuleTime, calculatedPageTime, isModuleTime, isPageTime);

            if (isModuleTime && isPageTime) {
                dispatch({
                    type: 'setModuleTimerTime',
                    payload: times["restModuleTime"]
                });
                dispatch({
                    type: 'setPageTimerTime',
                    payload: times["restPageTime"]
                });
            } else if (isModuleTime) {
                dispatch({
                    type: 'setModuleTimerTime',
                    payload: times["restModuleTime"]
                });
            } else if (isPageTime) {
                dispatch({
                    type: 'setPageTimerTime',
                    payload: times["restPageTime"]
                });
            } else {
                dispatch({
                    type: 'setModuleTimerTime',
                    payload: calculatedModuleTime
                });
                dispatch({
                    type: 'setPageTimerTime',
                    payload: calculatedPageTime
                });
            }
        }
    }, [
        module.responseTime,
        page.responseTime,
        setCalculatedModuleTimerTime,
        calculatedModuleTimerTime,
        setCalculatedPageTimerTime,
        calculatedPageTimerTime,
        calculateRestTime,
        answerJson,
        moduleIndex,
        moduleJson,
        dispatch
    ]);

    return (
        <Fragment>
            <Box mb={2}>
                <Notifications error={notificationError} errorMessage={errorMessage} cols={{
                    xs: 12
                }}/>
            </Box>

            {
                state.triggerFinishPage &&
                !isOcsModulePage(state.pinRessource, state.moduleIndex, state.pinStarted, state.pinFinished) &&
                <Timer onTimeResponseFinish={state.triggerFinishPage}
                       initialSeconds={isModuleTime ? state.moduleTimerTime : state.pageTimerTime}
                       finishParameter={isModuleTime ? 'module' : 'page'}
                       styling={{
                           fontSize: 32,
                           fontWeight: 'bold'
                       }}
                />
            }

            <Box sx={{mb: 2}}>
                <RenderElements/>
            </Box>

            <Box sx={{textAlign: 'right'}}>
                <LoadingButton
                    loading={loading}
                    variant={"contained"}
                    type={"submit"}
                    onClick={submitAnswer}
                    disabled={(answeredCounter !== totalElementAnswers && totalElementAnswers !== 0)}
                >
                    {GlobalTrans('continue')}
                </LoadingButton>
            </Box>
        </Fragment>
    );
};

Content.propTypes = {
    onlyTasks: PropTypes.bool,
}

export default Content;
