import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import '../../../assets/css/cameratag.css';
import {Box, Button, Grid} from "@mui/material";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import {useStore} from "../../../store/useStore";

const RendererVideoRecord = (props) => {
    const intl = useIntl();
    const {state, dispatch} = useStore();
    const videoRecordId = "video-record-" + props.element.id;
    const hiddenInputRef = useRef();
    const [initialised, setInitialised] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [cameratagMinIsLoaded, setCameratagMinIsLoaded] = useState(false);
    const [loadCameratag, setLoadCameratag] = useState(true);

    useEffect(() => {
        return () => {
            if (localStorage.getItem(videoRecordId)) {
                localStorage.removeItem(videoRecordId);
            }
        }
    }, [videoRecordId]);

    useEffect(() => {
        if (!initialised) {
            setInitialised(true);

            props.finishingElement(true);

            if (localStorage.getItem(videoRecordId)) {
                props.onChangeHandler(localStorage.getItem(videoRecordId));
                props.answered();
            } else {
                setShowCamera(true);
            }
        }
    }, [videoRecordId, initialised, props]);

    const handleButtonOnClick = () => {
        props.onChangeHandler(hiddenInputRef.current.value);
        props.answered();
        props.finishingElement(false);

        if (state.finishingPage) {
            dispatch({
                type: 'setFinishingPage',
                payload: false,
            });
            setLoadCameratag(false);
            props.triggerFinishedPage();
        } else if (props.element.autoFinish) {
            setLoadCameratag(false);
            props.triggerFinishedPage();
        } else {
            localStorage.setItem(videoRecordId, hiddenInputRef.current.value);
        }
    }

    return (
        <div>
            <Helmet>
                {
                    loadCameratag &&
                    <script
                        onLoad={'document.getElementById("cameratagMinIsLoaded").click()'}
                        src={'//www.cameratag.com/v15/js/cameratag.min.js'}
                        type={'text/javascript'}
                        async
                    />
                }
                {
                    cameratagMinIsLoaded &&
                    <script type={'text/javascript'}>
                        {
                            'if (typeof(CT_i18n) == "undefined") {CT_i18n = []}' +
                            'CT_i18n[100] = "' + GlobalTransIntl('cameratag_waiting_for_hardware', intl) + '";' +
                            'CT_i18n[10] = "' + GlobalTransIntl('cameratag_uploading', intl) + '";' +
                            'CT_i18n[17] = "' + GlobalTransIntl('cameratag_published', intl) + '";' +
                            'CT_i18n[38] = "' + GlobalTransIntl('cameratag_publishing', intl) + '";' +
                            'CT_i18n[39] = "' + GlobalTransIntl('cameratag_connecting', intl) + '";' +
                            'CameraTag.scan();' +
                            'CameraTag.observe("' + videoRecordId + '", "published", function () {' +
                            'const myCamera = CameraTag.cameras["' + videoRecordId + '"];' +
                            'const myVideo = myCamera.getVideo();' +
                            'document.getElementById("videoRecordValue").value = myVideo.uuid;' +
                            'document.getElementById("finishedRecordButton").click();' +
                            '})'
                        }
                    </script>
                }
                {
                    state.finishingPage &&
                    <script type={'text/javascript'}>
                        {
                            'const myCamera = CameraTag.cameras["' + videoRecordId + '"];' +
                            'myCamera.stopRecording("' + videoRecordId + '")'
                        }
                    </script>
                }
            </Helmet>
            <Box display={(showCamera) ? '' : 'none'}>
                <camera
                    id={videoRecordId}
                    data-app-id={props.element.cameratagAppId}
                    data-sources={'["record"]'}
                    data-facingmode={'environment'}
                    data-skiphaircheck={"true"}
                    data-preroll={props.element.preroll}
                    data-skippreview={props.element.autoPublish}
                    data-maxlength={props.element.maxLength}
                    data-minlength={props.element.minLength}
                    data-videobitrate={props.element.videoBitrate}
                    data-audiobitrate={props.element.audioBitrate}
                    data-width={props.element.width}
                    data-height={props.element.height}
                >
                    <Grid container className="start-screen" justifyContent={"center"}>
                        <Grid item alignSelf={"center"}>
                            <Button variant="contained" type="button" className='cameratag_record'>
                                {GlobalTrans('cameratag_start_record')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className="error-screen" justifyContent={"center"}>
                        <Grid item alignSelf={"center"}>
                            <Box height={30}>
                                {GlobalTrans('cameratag_error_text')}
                            </Box>
                            <Button variant="contained" type="button" className='cameratag_record'>
                                {GlobalTrans('cameratag_error_button')}
                            </Button>
                        </Grid>
                    </Grid>
                </camera>
            </Box>
            {
                !showCamera &&
                <div>{GlobalTransIntl('cameratag_already_recorded', intl)}</div>
            }
            <Box display={'none'}>
                <input
                    type={"hidden"}
                    ref={hiddenInputRef}
                    id={'videoRecordValue'}
                    value={localStorage.getItem(videoRecordId) || ''}
                />
                <button onClick={handleButtonOnClick} id={'finishedRecordButton'}/>
                <button onClick={() => setCameratagMinIsLoaded(true)} id={'cameratagMinIsLoaded'}/>
            </Box>
        </div>
    );
};

RendererVideoRecord.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
    triggerFinishedPage: PropTypes.func.isRequired,
    finishingElement: PropTypes.func.isRequired,
}

export default RendererVideoRecord;