import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {Box, FormControl, FormControlLabel, RadioGroup, Typography} from "@mui/material";
import Radio from '@mui/material/Radio';
import GetTranslation from "../../../helper/GetTranslation";
import config from "../../../config/config";
import {useStore} from "../../../store/useStore";
import DOMPurify from "dompurify";

const RendererRadio = (props) => {
    const { state } = useStore();

    const [answered, setAnswered] = useState(false);

    const handleChange = (event) => {
        props.onChangeHandler(parseInt(event.target.value, 10));

        if (!answered) {
            setAnswered(true);
            props.answered();
        }
    };

    const getTranslation = useCallback(() => {
        if (props.element?.translations) {
            const translation = props.element.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                return translation;
            }
        }

        return null;
    }, [props.element.translations, state.localeIRI]);

    const getOptionTranslation = useCallback((index) => {
        if (!props.element.options[index]) {
            return index;
        }

        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.options) {
                return translation.options[index];
            }

            return props.element.options[index];
        } else {
            return props.element.options[index];
        }
    }, [props.element.options, state.localeIRI, getTranslation]);

    return (
        <React.Fragment>
            <Typography mb={2}>
                <GetTranslation
                    translations={props.element?.translations}
                    defaultContent={props.element.question}
                    translationField={'question'}
                />
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup name="radio-buttons-group" onChange={handleChange}>
                    {props.element.options.map((option, index) => (
                        <Box mb={2} key={index}>
                            <FormControlLabel
                                value={index}
                                control={<Radio/>}
                                label={<Box component={'label'} sx={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(getOptionTranslation(index).replace(/\n/g, "<br />"))}}></Box>}
                            />
                        </Box>
                    ))}
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );
}

RendererRadio.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
}

export default RendererRadio;