import React, {useEffect} from "react";
import {useStore} from "./store/useStore";
import {IntlProvider} from "react-intl";
import * as Sentry from "@sentry/react";

// Local files
import translations from "./translations/locales";
import './assets/scss/app.scss';

// DnD
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

// Components
import Layout from "./pages/layout/Layout";
import Error from "./pages/error/Error";

function App() {
    const {state} = useStore();

    useEffect(() => {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme-${state.theme}`);
    }, [state.theme]);

    return (
        <IntlProvider
            locale={state.locale}
            defaultLocale="en"
            key={state.locale}
            messages={translations[state.locale]}
        >
            <Sentry.ErrorBoundary fallback={<Error/>}>
                <DndProvider backend={HTML5Backend}>
                    <Layout/>
                </DndProvider>
            </Sentry.ErrorBoundary>
        </IntlProvider>
    );
}

export default App;
