import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

// Element components
import RendererText from "../components/renderer/contentElements/RendererText";
import RendererRadio from "../components/renderer/contentElements/RendererRadio";
import RendererQuestionnaire from "../components/renderer/contentElements/RendererQuestionnaire";
import RendererImage from "../components/renderer/contentElements/RendererImage";
import RendererHtml from "../components/renderer/contentElements/RendererHtml";
import RendererGrid from "../components/renderer/contentElements/RendererGrid";
import RendererForcedChoice from "../components/renderer/contentElements/RendererForcedChoice";
import RendererTextInput from "../components/renderer/contentElements/RendererTextInput";
import RendererDropdown from "../components/renderer/contentElements/RendererDropdown";
import RendererRightAndWrong from "../components/renderer/contentElements/RendererRightAndWrong";
import RendererVideoRecord from "../components/renderer/contentElements/RendererVideoRecord";
import RendererMailTasks from "../components/renderer/contentElements/RendererMailTasks";
import RendererTextarea from "../components/renderer/contentElements/RendererTextarea";
import RendererVideo from "../components/renderer/contentElements/RendererVideo";
import RendererAdaptive from "../components/renderer/contentElements/RendererAdaptive";
import RendererTable from "../components/renderer/contentElements/RendererTable";

const GetElement = (props) => {
    const onChangeHandler = (value) => props.setValues(
        (prev) => ({
            ...prev,
            [props.element.id]: value
        })
    );

    const getElementAnswers = useCallback(() => {
        let elementAnswers = {};

        if (Object.keys(props.answerJson) && props.answerJson[props.pageId] && props.answerJson[props.pageId].elements[props.element.id]) {
            const elementAnswer = props.answerJson[props.pageId].elements[props.element.id];

            if (elementAnswer.constructor === Array) {
                Object.assign(elementAnswers, elementAnswer);
            } else {
                elementAnswers = elementAnswer;
            }
        }

        return elementAnswers;
    }, [props.answerJson, props.pageId, props.element]);

    switch (props.element.elementType) {
        case 'text':
            return <RendererText element={props.element}/>;
        case 'textarea':
            return <RendererTextarea element={props.element}/>;
        case 'html':
            return <RendererHtml element={props.element}/>;
        case 'table':
            return <RendererTable element={props.element}/>;
        case 'radio':
            return <RendererRadio
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
            />;
        case 'grid':
            return <RendererGrid
                element={props.element}
                setValues={props.setValues}
                increaseAnswered={props.increaseAnswered}
                decreaseAnswered={props.decreaseAnswered}
                answerJson={props.answerJson}
                setReadyForNextPage={props.setReadyForNextPage}
                pageId={props.pageId}
            />;
        case 'mailTasks':
            return <RendererMailTasks
                element={props.element}
                setValues={props.setValues}
                increaseAnswered={props.increaseAnswered}
                decreaseAnswered={props.decreaseAnswered}
                answerJson={props.answerJson}
                setReadyForNextPage={props.setReadyForNextPage}
                pageId={props.pageId}
                triggerFinishedPage={props.triggerFinishedPage}
                finishingElement={props.finishingElement}
            />;
        case 'questionnaire':
            return <RendererQuestionnaire
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
                answers={getElementAnswers()}
                setReadyForNextPage={props.setReadyForNextPage}
            />;
        case 'sort':
            return <RendererForcedChoice
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
            />;
        case 'textInput':
            return <RendererTextInput
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
                notAnswered={props.decreaseAnswered}
            />;
        case 'dropdown':
            return <RendererDropdown
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
            />
        case 'video':
            return <RendererVideo element={props.element}/>;
        case 'video_record':
            return <RendererVideoRecord
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
                triggerFinishedPage={props.triggerFinishedPage}
                finishingElement={props.finishingElement}
            />
        case 'rightAndWrong':
            return <RendererRightAndWrong
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
                decreaseAnswered={props.decreaseAnswered}
            />;
        case 'adaptive_tree':
            return <RendererAdaptive
                element={props.element}
                onChangeHandler={onChangeHandler}
                answered={props.increaseAnswered}
                answers={getElementAnswers()}
                setReadyForNextPage={props.setReadyForNextPage}
                triggerFinishedPage={props.triggerFinishedPage}
                setTriggerFinishPageValue={props.setTriggerFinishPageValue}
            />


        case 'image':
            return <RendererImage element={props.element}/>
        default:
            return null;
    }
};

GetElement.propTypes = {
    element: PropTypes.object.isRequired,
    setValues: PropTypes.func,
    increaseAnswered: PropTypes.func,
    decreaseAnswered: PropTypes.func,
    answerJson: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setReadyForNextPage: PropTypes.func,
    pageId: PropTypes.string.isRequired,
    triggerFinishedPage: PropTypes.func,
    finishingElement: PropTypes.func,
    setTriggerFinishPageValue: PropTypes.func,
}

export default GetElement;