import React from 'react';
import PropTypes from "prop-types";
import GetTranslation from "../../../helper/GetTranslation";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DOMPurify from "dompurify";
import config from "../../../config/config";

const RendererTable = (props) => {

    const textareaOutput = (value) => {
        return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value.replace(/\n/g, "<br />"))}}/>;
    }

    const replaceTextStyling = (value) => {
        return value
            .replaceAll("[b]", "<b>")
            .replaceAll("[/b]", "</b>");
    }

    const getImage = (cell, key) => {
        return (
            <Box sx={{maxWidth: cell.imageMaxWidth, display: 'inline-block'}} key={ key + '_image'}>
                <img
                    src={config.apiHost + cell.imageOriginal.contentUrl}
                    alt={''}
                    className={'image-responsive'}
                />
            </Box>
        );
    }

    const getCellContentWithImage = (cell, rowIndex, colIndex) => {
        const key = rowIndex + '_' + colIndex;

        if (!cell.imageOriginal) {
            return (
                <Grid container spacing={2} justifyContent={cell.align}>
                    <Grid item>
                        {textareaOutput(replaceTextStyling(cell.value || ''))}
                    </Grid>
                </Grid>
            );
        }

        if (cell.alignImage === 'top' || cell.alignImage === 'bottom') {
            return (
                <>
                    {
                        (cell.alignImage === 'top') &&
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {getImage(cell, key)}
                            </Grid>
                        </Grid>
                    }
                    {
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {textareaOutput(replaceTextStyling(cell.value || ''))}
                            </Grid>
                        </Grid>
                    }
                    {
                        (cell.alignImage === 'bottom') &&
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {getImage(cell, key)}
                            </Grid>
                        </Grid>
                    }
                </>
            );
        }

        return (
            <Grid container spacing={2} justifyContent={cell.align}>
                <>
                    {
                        (cell.alignImage === 'left') &&
                        <Grid item>
                            {getImage(cell, key)}
                        </Grid>
                    }
                    <Grid item>
                        {textareaOutput(replaceTextStyling(cell.value || ''))}
                    </Grid>
                    {
                        (cell.alignImage === 'right') &&
                        <Grid item>
                            {getImage(cell, key)}
                        </Grid>
                    }
                </>
            </Grid>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            props.element.content[0].map((cell, cellIndex) => (
                                <TableCell key={cellIndex} colSpan={cell.cs} rowSpan={cell.rs} sx={{verticalAlign: cell.valign, fontSize: cell.size}}>
                                    {getCellContentWithImage(GetTranslation({
                                        translations: props.element?.translations?.[0]?.[cellIndex],
                                        defaultContent: cell
                                    }), 0, cellIndex)}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.element.content.map((row, rowIndex) => (
                        (rowIndex > 0) &&
                        <TableRow key={rowIndex}>
                            {
                                row.map((cell, cellIndex) => (
                                    <TableCell scope="row" key={cellIndex} colSpan={cell.cs} rowSpan={cell.rs} sx={{verticalAlign: cell.valign}}>
                                        {getCellContentWithImage(GetTranslation({
                                            translations: props.element?.translations?.[rowIndex]?.[cellIndex],
                                            defaultContent: cell
                                        }), rowIndex, cellIndex)}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

RendererTable.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererTable;