import React, {useCallback, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Collapse,
    Container,
    Divider,
    Grid,
    Hidden,
    IconButton,
    Link,
    List,
    Paper,
    Typography,
} from "@mui/material";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MailIcon from '@mui/icons-material/Mail';
import VideocamIcon from '@mui/icons-material/Videocam';
import {useStore} from "../../store/useStore";
import config from "../../config/config";
import Content from "../../components/renderer/Content";
import GetElement from "../../helper/GetElement";
import Timer from "../../components/renderer/Timer";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import GetTranslation from "../../helper/GetTranslation";

const Ocs = () => {
    const intl = useIntl();
    const {state} = useStore();

    const [moduleJson] = useState(state.pinRessource.modulesJson);
    const [totalTasks, setTotalTasks] = useState(0);

    const getMailLayouts = () => {
        const mails = [];

        moduleJson[state.moduleIndex]?.pages.forEach(page => {
            if (page.elements[0]?.elementType === 'mailLayout') {
                mails.push(page.elements[0]);
            }
        });

        return mails.slice().reverse();
    };

    const [mails] = useState(getMailLayouts());

    const iconFunction = (type) => {
        if (type === 1) {
            return <MailIcon/>;
        }

        return <VideocamIcon/>;
    }

    const countTasks = useCallback((elements) => {
        let counter = 0;

        elements.forEach(element => {
            if (element.validationType) {
                if (element.elementType === 'grid' || element.elementType === 'mailTasks') {
                    element.colElements.forEach(col => {
                        counter += countTasks(col);
                    })
                } else {
                    counter++;
                }
            }
        });

        return counter;
    }, []);

    useEffect(() => {
        const page = moduleJson[state.moduleIndex].pages[state.pageIndex];

        setTotalTasks(countTasks(page.elements));
    }, [moduleJson, state.moduleIndex, state.pageIndex, countTasks]);

    const pageTime = useCallback(() => {
        const page = moduleJson[state.moduleIndex].pages[state.pageIndex];

        if (page) {
            const seconds = page.responseTime;
            const minutes = Math.floor(seconds / 60);
            let restSeconds = seconds - minutes * 60;

            if (restSeconds === 0) {
                return minutes + ' ' + GlobalTransIntl('minutes', intl);
            }

            return GlobalTransIntl('time_minutes_and_seconds', intl, {minutes, seconds: restSeconds});
        }
        return null;
    }, [moduleJson, state, intl]);

    const inbox = useCallback(() => {
        return mails.map((item, key) => {
            const index = (mails.length - 1) - key;

            if (index <= state.pageIndex) {
                return (
                    <Box key={key}
                         className={(index === state.pageIndex) ? "" : "inactive-mail"}
                         py={2}
                         borderBottom={1}
                    >
                        <Box>
                            {iconFunction(item.icon)}
                        </Box>
                        <Box>
                            <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.from}
                                translationField={'from'}
                            />
                        </Box>
                        <Box>
                            <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.date}
                                translationField={'date'}
                            />
                        </Box>
                        <Box>
                            <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.subject}
                                translationField={'subject'}
                            />
                        </Box>
                    </Box>
                );
            }

            return null;
        });
    }, [mails, state.pageIndex]);

    const RenderElements = useCallback(() => {
        const page = moduleJson[state.moduleIndex].pages[state.pageIndex];

        return page.elements.map((element) => {
            if (!element.validationType) {
                return (
                    <Box sx={{mb: (page.elements.length > 1) ? 6 : 0}} key={element.id}>
                        <GetElement
                            element={element}
                            pageId={page.id}
                        />
                    </Box>
                )
            }

            return null;
        })
    }, [moduleJson, state.moduleIndex, state.pageIndex]);

    const replaceTime = useCallback((info) => {
        const time = pageTime();

        return info.replace('%time%', time);
    }, [pageTime]);

    const mailContent = useCallback((mails) => {
        const item = mails[(mails.length - 1) - state.pageIndex];

        return (
            <React.Fragment>
                <Box mb={2}>
                    <Alert severity="info" sx={{whiteSpace:'pre-line'}}>
                        {
                            replaceTime(GetTranslation({
                                translations: item?.translations,
                                defaultContent: item.info,
                                translationField: 'info'
                            }))
                        }
                    </Alert>
                </Box>

                <Box mb={2} mt={2}>
                    <b>
                        <GetTranslation
                            translations={item?.translations}
                            defaultContent={item.subject}
                            translationField={'subject'}
                        />
                    </b>
                </Box>

                <Divider/>

                <Grid container>
                    <Grid item xs={6}>
                        <p>{GlobalTransIntl('from', intl)}: <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.from}
                                translationField={'from'}
                            />
                        </p>
                        <p>{GlobalTransIntl('to', intl)}: <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.to}
                                translationField={'to'}
                            />
                        </p>
                    </Grid>
                    <Grid item xs={6} textAlign='right' margin={'auto'}>
                        <p>
                            <GetTranslation
                                translations={item?.translations}
                                defaultContent={item.date}
                                translationField={'date'}
                            />
                        </p>
                    </Grid>
                </Grid>

                <Divider/>

                <RenderElements/>
            </React.Fragment>
        );
    }, [state.pageIndex, replaceTime, intl]);

    const attachments = useCallback(() => {
        return moduleJson[state.moduleIndex].references.map((item, key) => (
            <Box mb={2} key={key}>
                <Link href={config.apiHost + GetTranslation({
                    translations: item?.translations,
                    defaultContent: item.contentUrl,
                    translationField: 'contentUrl'
                })} target={'_blank'} color={'inherit'}>
                    <Grid container>
                        <Grid item>
                            <Box display={'flex'} alignItems={"center"} height={'100%'}>
                                <PictureAsPdfIcon/>
                            </Box>
                        </Grid>

                        <Grid item xs alignSelf={"center"}>
                            <Box ml={2}>
                                <GetTranslation
                                    translations={item?.translations}
                                    defaultContent={item.name}
                                    translationField={'name'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Link>
            </Box>
        ));
    }, [moduleJson, state.moduleIndex]);

    const [checkedInbox, setCheckedInbox] = React.useState(false);
    const [checkedFactSheets, setCheckedFactSheets] = React.useState(false);

    const getFinishedTasksAmount = () => {
        const page = moduleJson[state.moduleIndex].pages[state.pageIndex];

        if (Object.keys(state.answerJson).length && state.answerJson[page.id]) {
            return Object.keys(state.answerJson[page.id].elements).length + 1;
        }

        return 1;
    }

    const handleChangeInbox = () => {
        setCheckedInbox((prev) => !prev);
    };
    const handleChangeFactSheets = () => {
        setCheckedFactSheets((prev) => !prev);
    };

    return (
        <Container maxWidth={'xl'}>
            <div className={'ocs-layout'}>
                <Paper sx={{mt: 4}} className={'color-theme main-paper'}>
                    {/*Header, Logo, Timer*/}
                    <Grid container spacing={1}>
                        <Grid item container xs={6} spacing={1}>
                            <Grid item xs={12} sm={3} margin={'auto'}>
                                <img src="https://assessmentworks.de/custom/onlinefallstudie/custom/Logo.png"
                                     alt="Logo BMC" className={'image-responsive'}/>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <h3>
                                    {GlobalTrans('ocs_company')}
                                </h3>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            {
                                state.moduleTimerTime > 0 &&
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={9}>
                                        <Box textAlign={'right'} fontWeight={'bold'}>
                                            {GlobalTransIntl('rest_time_test', intl)}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box textAlign={'center'}>
                                            <Timer onTimeResponseFinish={state.triggerFinishPage}
                                                   initialSeconds={state.moduleTimerTime}
                                                   finishParameter={'module'}
                                                   styling={{
                                                       fontSize: 32,
                                                       fontWeight: 'bold'
                                                   }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            {
                                state.pageTimerTime > 0 &&
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={9}>
                                        <Box textAlign={'right'} fontWeight={'bold'}>
                                            {GlobalTransIntl('rest_time_task', intl)}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box textAlign={'center'}>
                                            <Timer onTimeResponseFinish={state.triggerFinishPage}
                                                   initialSeconds={state.pageTimerTime}
                                                   styling={{
                                                       fontSize: 32,
                                                       fontWeight: 'bold'
                                                   }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {/*ProgressText*/}
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Box
                                component="h4"
                                sx={{fontSize: '1.25rem', fontWeight: 'bold', margin: '4px'}}
                            >
                                {GlobalTransIntl('progress_mails', intl, {x: state.pageIndex + 1, y: mails.length})}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                component="h4"
                                textAlign="right"
                                sx={{fontSize: '1.25rem', fontWeight: 'bold', margin: '4px'}}
                            >
                                {GlobalTransIntl('progress_questions', intl, {
                                    x: getFinishedTasksAmount(),
                                    y: totalTasks
                                })}
                            </Box>
                        </Grid>
                    </Grid>


                    <Divider/>

                    {/*Ausklapp text und FactSheets*/}
                    <Hidden lgUp>
                        <Box>
                            <Grid container spacing={1}>
                                {/*Inbox*/}
                                <Grid item container xs={6} spacing={2}>
                                    <Grid item xs={12}>
                                        <IconButton edge="start" onClick={handleChangeInbox} color="inherit"
                                                    aria-label={GlobalTransIntl('inbox', intl)} disableRipple
                                                    disableFocusRipple>
                                            <MailIcon/>
                                            <Typography variant="h6" ml={1}>
                                                {GlobalTransIntl('inbox', intl)}
                                            </Typography>
                                        </IconButton>
                                        <Collapse in={checkedInbox}>
                                            <Paper className={"secondary-paper"}>
                                                <div className={"scroll"}>
                                                    {inbox(mails)}
                                                </div>
                                            </Paper>
                                        </Collapse>
                                    </Grid>
                                </Grid>

                                {/*FactSheets*/}
                                <Grid item container xs={6} spacing={2}>
                                    <Grid item xs={12} textAlign={'right'}>
                                        <IconButton edge="start" onClick={handleChangeFactSheets} color="inherit"
                                                    aria-label={GlobalTransIntl('fact_sheets', intl)} disableRipple
                                                    disableFocusRipple>
                                            <Typography variant="h6" paddingRight={'8px'}>
                                                {GlobalTransIntl('fact_sheets', intl)}
                                            </Typography>
                                            <PictureAsPdfIcon/>
                                        </IconButton>
                                        <Collapse in={checkedFactSheets}>
                                            <Paper className={"secondary-paper"}>
                                                <div className={"scroll"}>
                                                    {attachments()}
                                                </div>
                                            </Paper>
                                        </Collapse>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Box>
                    </Hidden>

                    <Grid container spacing={2}>

                        {/*Inbox, on the left*/}
                        <Hidden lgDown>
                            <Grid item xs={12} md>
                                <Box>
                                    <h2>{GlobalTransIntl('inbox', intl)}</h2>
                                    <Box>
                                        {inbox()}
                                    </Box>
                                </Box>
                            </Grid>
                        </Hidden>

                        {/*MailContent, in the middel*/}
                        <Grid item xs={12} lg={8}>
                            <Box p={2}>

                                {/*MailContent*/}
                                {mailContent(mails)}

                                <Box mb={2}>
                                    <Divider/>
                                </Box>

                                {/*InfoBox unten*/}
                                {
                                    state.pageTimerTime > 0 &&
                                    <Box mb={2}>
                                        <Alert icon={false} variant="outlined" severity="info" mt={1}>
                                            <Grid container>
                                                <Grid item container alignItems={"center"} mb={1} spacing={2}>
                                                    <Grid item xs={1}>
                                                        <Timer initialSeconds={state.pageTimerTime}
                                                               styling={{
                                                                   fontSize: 21,
                                                                   fontWeight: 'bold'
                                                               }}/>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        {GlobalTransIntl('rest_minutes_for_this_mail_video', intl)}
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    {GlobalTransIntl('mail_tasks_info_box_bottom_text', intl, {
                                                        x: getFinishedTasksAmount(),
                                                        y: totalTasks,
                                                        br: <br/>
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Alert>
                                    </Box>
                                }
                                <Box>
                                    <Content onlyTasks={true}/>
                                </Box>
                            </Box>
                        </Grid>

                        {/*FactSheets wenn größer MD*/}
                        <Hidden lgDown>
                            <Grid item xs={12} md>
                                <Box>
                                    <h2>{GlobalTransIntl('fact_sheets', intl)}</h2>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {attachments()}
                                    </List>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Paper>
            </div>
        </Container>
    );
}

export default Ocs;