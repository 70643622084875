import React from 'react';
import {Alert, AlertTitle, Container} from "@mui/material";
import GlobalTrans from "../../helper/GlobalTrans";

const Error = () => {
    return (
        <main className='main-content'>
            <Container className='main-content--container container--absolute-center'>
                <Alert severity="error">
                    <AlertTitle>{GlobalTrans('error')}</AlertTitle>
                    <p>{GlobalTrans('error_message_one')}</p>
                    <p>{GlobalTrans('error_message_two')}</p>
                </Alert>
            </Container>
        </main>
    );
};

export default Error;